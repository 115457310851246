@import "../../../variables";
@import "../../../../components/headers";
@import "bootstrap/scss/mixins/breakpoints";

.plain-textheader {
    margin: 0 auto;

    &.color-slate {
        color: $slate;
    }

    &.color-white {
        color: $white;
    }

    .header-text {
        text-transform: uppercase;
        margin-bottom: 0;
    }
}
